<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Edit Item Form</span>
    </v-card-title>
      <v-container>
          <input-label>
          Item Name
        </input-label>
          <v-text-field
            v-model="itemName"
            label="Item Name"
            :rules="[rules.spelling, rules.counter]"
            hide-details="auto"
          ></v-text-field>
        <br>
      <input-label>
        Description
      </input-label>

        <v-textarea
          v-model="description"
          name="input-7-1"
          label="Description"
          hint=""
          flat
          solo
          outlined
        ></v-textarea>
        <input-label>
        About
      </input-label>
        <v-textarea
          v-model="about"
          name="input-7-1"
          label="About"
          hint=""
          flat
          solo
          outlined
        ></v-textarea>
            <v-btn
        class="mr-4"
        :disabled="!passValidation"
        @click="submit"
      >
        Update
      </v-btn>
      <v-btn @click="close">
        close
      </v-btn>
      </v-container>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import formValidator from '@/lib/formValidators/formValidator';
import itemAPI from '@/api/item';

export default {
  name: 'ProfileSettingsForm',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    itemName: '',
    description: '',
    about: '',
    errorMessages: {
      usernameMsg: '',
    },
    rules: { // rules for user input
      counter: (value) => value.length <= 30 || 'Max 30 characters',
      spelling: (value) => {
        const regex = /^[\w\d-_]*$/g;
        return regex.test(value) || 'Invalid Item Name';
      },
      checkEmail: formValidator.validateEmail,
    },
  }),
  computed: {
    ...mapGetters({ token: 'user/token' }),
    passValidation() { // check validation
      let pass = (this.itemName !== ''
      || this.description !== ''
      || this.about !== '');
      if (this.itemName) {
        pass = pass && typeof this.rules.counter(this.itemName) === 'boolean';
        pass = pass && typeof this.rules.spelling(this.username) === 'boolean';
      }
      return pass;
    },
  },
  watch: {
    itemName(newVal) {
      if (newVal === null) {
        this.username = '';
      }
    },
  },
  methods: {
    ...mapActions({
      alert: 'alert/callErrorMessage',
    }),
    submit() {
      const data = {
        itemName: this.itemName,
        description: this.description,
        about: this.about,
      };
      itemAPI.updateItem(this.token, data, this.id).then(() => {
        this.$router.go();
      }).catch((err) => {
        console.log(err);
        // add alert to system
      });
    },
    close() { // need to reset state
      this.itemName = '';
      this.description = '';
      this.about = '';
      this.$emit('close');
      // emit close
    },
    onType() {
      this.errorMessages.usernameMsg = '';
    },
  },
};
</script>
